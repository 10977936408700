import React, { useState } from 'react';
import "../App.css";
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';

function TableRow(props) {

    const navigate = useNavigate();

    const navToIncidentInfo = () => {
        navigate('/viewIncidentInfo')
    }

    const handleReportClick = (e) => {
        console.log("Report Button Clicked")
        var obj = {
            reportViewData: props.resources
        };

        fetch("/getData/reportViewData", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }

    const handleReportClickTwo = (e) => {
        var obj = {
            reportViewData: props.resources
        };

        fetch("/postData/reportViewData", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }

    const [incidentInfo, setIncidentInfo] = useState([]);

    // useEffect to fetch Uni Incidents
    React.useEffect(() => {
        fetch("/getData/incidents")
            .then((res) => res.json())
            .then((data) => setIncidentInfo(data.message));
    }, []);

    const handleIncidentDelete = (e) => {
        var obj = {
            data: incidentInfo
        };
        fetch("/postData/incidentList", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        })
    }

    return (
        <tr>
            <td><a class='pointer noLinkView repoClick' onClick={e => { handleIncidentDelete(); handleReportClick(); handleReportClickTwo(); navToIncidentInfo(); }}>{props.resources.greekOrganizationName}</a></td>
            <td><a class='pointer noLinkView repoClick' onClick={e => { handleIncidentDelete(); handleReportClick(); handleReportClickTwo(); navToIncidentInfo(); }}>{props.resources.dateCharge}</a></td>
            <td><a class='pointer noLinkView repoClick' onClick={e => { handleIncidentDelete(); handleReportClick(); handleReportClickTwo(); navToIncidentInfo(); }}>{props.resources.incidentSeverity}</a></td>
            <td><a class='pointer noLinkView repoClick' onClick={e => { handleIncidentDelete(); handleReportClick(); handleReportClickTwo(); navToIncidentInfo(); }}>{props.resources.incidentType}</a></td>
            <td><a class='pointer noLinkView repoClick' onClick={e => { handleIncidentDelete(); handleReportClick(); handleReportClickTwo(); navToIncidentInfo(); }}><button class='view-report-button pointer'>View Report</button></a></td>
        </tr>
    );
}
export function IncidentTable() {

    const [incidentInfo, setIncidentInfo] = useState([]);

    // useEffect to fetch Uni Incidents
    React.useEffect(() => {
        fetch("/getData/incidents")
            .then((res) => res.json())
            .then((data) => setIncidentInfo(data.message));
    }, []);

    const val = incidentInfo.map((input) => {
        return <TableRow resources={input} key={input.id} />
    });


    return (
        <div>
            <div className='incident-data'>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Chapter Name</th>
                            <th>Charge Date</th>
                            <th>Hazing Severity</th>
                            <th>Incident Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {val}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}