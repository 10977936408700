import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SchoolDropDown } from './SchoolDropDown';
import Popup from 'reactjs-popup';
import { useAuth0 } from '@auth0/auth0-react';

export function AddFirstIncident() {

    const { isAuthenticated } = useAuth0();
    const nav = useNavigate();
    if (!isAuthenticated) {
        nav('/');
    }

    // School Name State
    const [uniName, setUniName] = useState([]);

    const [firstRender, setFirstRender] = useState(false);
    React.useEffect(() => {
        setTimeout(() => { setFirstRender(false); }, 650);
    }, []);

    const navToHomePage = async () => {
        nav(-1)
    }

    const navToIncidentPage = () => {
        nav('/viewIncidentPage')
    }

    const SubmitPopUp = () => (
        <Popup trigger={<button style={{ marginRight: '10px' }} type="button" class="btn btn-primary">Submit</button>} modal>
            <div>
                <div className='pop-up-container'>
                    <h4>Successfully added to DB</h4>
                    <p>Return to the View Incident Page</p>
                    {/* {window.location.reload} */}
                    {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
                    <button onClick={e => { handleSave(); }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
                </div>
            </div>
        </Popup>
    );

    const navHome = async () => {
        nav('/homePage')
    }

    const cancelButton = () => {
        nav(-1)
    }

    const submitButton = () => {
        nav('./')
    }

    // useEffect to fetch School Names
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    var chapterName, chapterSymbol, type, severity, description, entity, dateInvestigation,
        dateInvestigationEnded, dateCharge, violations, sanctions, outLetter, news, extraLink, greekType,
        incidentDate, pendingCharge, incidentYear

    function isValidURL(url) {
        if(url === "") {
            return true;
        }
        var pattern = new RegExp('^(https?:\/\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(url);
    }

    const handleInputChange = (event) => {
        const newVal = event.target
        // Reset the firstRender state when changes are made to trigger revalidation
        setFirstRender(false);
    };

    const handleInputValidation = async (e) => {
        var inputIssueCount = 0

        const generalValidation = /^(?=.*\S)?(?!.*[{}"']).*$/
        const emptyStringValidation = /^ +$/
        // const urlValidation = /^$|(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/

        // remove all previous error messages
        document.getElementById('error').innerHTML = ""

        {/* Validate each of the inputs*/ }
        chapterName = document.getElementById('greekOrgName')
        chapterName.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(chapterName.value) || emptyStringValidation.test(chapterName.value)) {
            inputIssueCount += 1
            handleInputIssues(chapterName)
        }

        chapterSymbol = document.getElementById('chapterSymbol')
        chapterSymbol.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(chapterSymbol.value) || emptyStringValidation.test(chapterSymbol.value)) {
            inputIssueCount += 1
            handleInputIssues(chapterSymbol)
        }

        type = document.getElementById('incidentType')
        type.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(type.value) || emptyStringValidation.test(type.value)) {
            inputIssueCount += 1
            handleInputIssues(type)
        }

        incidentDate = document.getElementById('incidentDate')
        incidentDate.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(incidentDate.value) || emptyStringValidation.test(incidentDate.value)) {
            inputIssueCount += 1
            handleInputIssues(incidentDate)
        }

        greekType = document.getElementById('greekType')
        greekType.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(greekType.value) || emptyStringValidation.test(greekType.value)) {
            inputIssueCount += 1
            handleInputIssues(greekType)
        }

        severity = document.getElementById('incidentSeverity')
        severity.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(severity.value) || emptyStringValidation.test(severity.value)) {
            inputIssueCount += 1
            handleInputIssues(severity)
        }

        description = document.getElementById('incidentDescription')
        description.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(description.value) || emptyStringValidation.test(description.value)) {
            inputIssueCount += 1
            handleInputIssues(description)
        }

        entity = document.getElementById('entity')
        entity.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(entity.value) || emptyStringValidation.test(entity.value)) {
            inputIssueCount += 1
            handleInputIssues(entity)
        }

        dateInvestigation = document.getElementById('dateInvestigation')
        dateInvestigation.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateInvestigation.value) || emptyStringValidation.test(dateInvestigation.value)) {
            inputIssueCount += 1
            handleInputIssues(dateInvestigation)
        }

        dateInvestigationEnded = document.getElementById('dateInvestigationEnded')
        dateInvestigationEnded.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateInvestigationEnded.value) || emptyStringValidation.test(dateInvestigationEnded.value)) {
            inputIssueCount += 1
            handleInputIssues(dateInvestigationEnded)
        }

        dateCharge = document.getElementById('dateCharge')
        dateCharge.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateCharge.value) || emptyStringValidation.test(dateCharge.value)) {
            inputIssueCount += 1
            handleInputIssues(dateCharge)
        }

        violations = document.getElementById('violations')
        violations.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(violations.value) || emptyStringValidation.test(violations.value)) {
            inputIssueCount += 1
            handleInputIssues(violations)
        }

        sanctions = document.getElementById('sanctions')
        sanctions.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(sanctions.value) || emptyStringValidation.test(sanctions.value)) {
            inputIssueCount += 1
            handleInputIssues(sanctions)
        }

        outLetter = document.getElementById('outLetter')
        outLetter.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(outLetter.value) || !isValidURL(outLetter.value) || emptyStringValidation.test(outLetter.value)) {
            inputIssueCount += 1
            handleInputIssues(outLetter)
        }

        news = document.getElementById('news')
        news.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(news.value) || !isValidURL(news.value) || emptyStringValidation.test(news.value)) {
            inputIssueCount += 1
            handleInputIssues(news)
        }

        extraLink = document.getElementById('extraLinks')
        extraLink.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(extraLink.value) || !isValidURL(extraLink.value) || emptyStringValidation.test(extraLink.value)) {
            inputIssueCount += 1
            handleInputIssues(extraLink)
        }

        pendingCharge = document.getElementById('pendingCharges')
        pendingCharge.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(pendingCharge.value) || emptyStringValidation.test(pendingCharge.value)) {
            inputIssueCount += 1
            handleInputIssues(pendingCharge)
        }

        {/* If all inputs are valid handleSave() but if not, then show the error message*/ }
        if (inputIssueCount == 0) {
            // handleSave()
            setFirstRender(true)
        } else {
            const errorMessage = document.createElement("p")
            errorMessage.textContent = "Please edit the inputs that are highlighted red. Check for empty spaces, curly brackets, quotation marks, and that you are including a valid URL."
            document.getElementById('error').appendChild(errorMessage)
        }
    }

    const handleInputIssues = async (element) => {
        console.log("handleInputIssues called")
        console.log("current element: " + element.id)
        element.style.backgroundColor = '#FF7276'
    }

    const handleSave = async (e) => {
        chapterName = document.getElementById('greekOrgName').value
        chapterSymbol = document.getElementById('chapterSymbol').value
        type = document.getElementById('incidentType').value
        incidentDate = document.getElementById('incidentDate').value
        greekType = document.getElementById('greekType').value
        severity = document.getElementById('incidentSeverity').value
        description = document.getElementById('incidentDescription').value
        entity = document.getElementById('entity').value
        dateInvestigation = document.getElementById('dateInvestigation').value
        dateInvestigationEnded = document.getElementById('dateInvestigationEnded').value
        dateCharge = document.getElementById('dateCharge').value
        violations = document.getElementById('violations').value
        sanctions = document.getElementById('sanctions').value
        outLetter = document.getElementById('outLetter').value
        news = document.getElementById('news').value
        extraLink = document.getElementById('extraLinks').value
        pendingCharge = document.getElementById('pendingCharges').value
        incidentYear = document.getElementById('incidentYear').value


        var obj = {
            universityName: uniName,
            chapterName: chapterName,
            incidentDate: incidentDate,
            chapterSymbol: chapterSymbol,
            greekType: greekType,
            type: type,
            severity: severity,
            description: description,
            entity: entity,
            dateInvestigation: dateInvestigation,
            dateInvestigationEnded: dateInvestigationEnded,
            dateCharge: dateCharge,
            violations: violations,
            sanctions, sanctions,
            outLetter, outLetter,
            news: news,
            extraLink: extraLink,
            pendingCharge: pendingCharge,
            incidentYear: incidentYear
        };


        fetch("/postData/addFirstIncident", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });

        navToIncidentPage();
    }

    const PopUp = ({ content }) => (
        <Popup trigger={<button id="question-button">?</button>} position="right center">
            <div>{content}</div>
        </Popup>
    );

    const content = [
        "The name of the primary greek organization involved in this incident.",
        "The greek letters associated with the primary greek organization.",
        "The type of incident (Hazing, Title IX, Alcohol, etc).",
        "The date that the incident occured.",
        "The year that the incident occured.",
        "Choose from the list of categories: Indimidation, Harassment, and Violence. If the incident falls under multiple categories, choose the most severe one. See the \"What is Hazing\" page for a in-depth description of what behaviors fall under each category.",
        "A detailed description of the incident.",
        "The body in charge the investigation of the incident.",
        "The date that the charge was brought upon the greek organization.",
        "The specific conduct violations that the greek organization is charged for.",
        "Sanctions/Penalties that the greek organization must complete.",
        "Are charges still pending for this incident?",
        "A valid link to any extra resources that are helpful to undestand this incident.",
        "A valid link to an outcome letter (or other official documentation) provided by the university.",
        "A valid link to any media coverage of this incident."
    ]


    return (
        <div>
            <div className='navigation'>
                <p><Link to="/homePage" onClick={navHome}>Home Page</Link>{' -> '}<Link to="/viewIncidentPage" onClick={navToHomePage}>Incidents Page</Link>{' -> '}Add Incident Page</p>
            </div>

            <h1 className='page-header'>
                <div className='header-wrapper'>
                    Add A New Incident at {uniName}
                </div>
            </h1>
            <div id="new-incident">

                <h3>General Chapter Information</h3>
                <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                    <div className="form-row" style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-col">
                            <label style={{ color: '#212529', fontWeight: '400' }} for="greekOrgName">Greek Organization Name:  <PopUp content={content[0]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="greekOrgName" name="greekOrgName"></input>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className="form-col">
                            <label style={{ color: '#212529', fontWeight: '400' }} for="chapterSymbol">Greek Chapter Symbol: <PopUp content={content[1]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="chapterSymbol" name="chapterSymbol"></input>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="greekType">Greek Chapter Type:</label>
                            <select onChange={handleInputChange} name="greekType" id="greekType" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                <option value="">Select</option>
                                <option value="Fraternity">Fraternity</option>
                                <option value="Sorority">Sorority</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                </div>

                <h3>Incident Information</h3>
                <div className='section-container'>
                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="incidentType">Incident Type: <PopUp content={content[2]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="incidentType" name="incidentType"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="incidentDate">Incident Date: <PopUp content={content[3]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="incidentDate" name="incidentDate"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="incidentYear">Incident Year: <PopUp content={content[4]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="number" id="incidentYear" name="incidentYear"></input><br></br>
                        </div>
                    </div>

                    <div className='section-container'>
                        <div className="form-row">
                            <div className="form-col">
                                <label style={{ color: '#212529' }} for="incidentSeverity">Incident Severity:  <PopUp content={content[5]} /></label> {/* Indimidation, Harassment, Violence */}
                                <select onChange={handleInputChange} name="incidentSeverity" id="incidentSeverity" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                    <option value="">Select</option>
                                    <option value="Intimidation">Intimidation</option>
                                    <option value="Harassment">Harassment</option>
                                    <option value="Violence">Violence</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label style={{ color: '#212529' }} for="incidentDescription">Incident Description: <PopUp content={content[6]} /></label>
                                <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="textarea" id="incidentDescription" name="incidentDescription"></input><br></br>
                            </div>
                        </div>
                    </div>
                </div>


                <h3>Investigation Information</h3>
                <div className='section-container'>
                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="entity">Investigation Entity: <PopUp content={content[7]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="entity" name="entity"></input><br></br>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="dateInvestigation">Date Investigation Began:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="dateInvestigation" name="dateInvestigation"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="dateInvestigationEnded">Date Investigation Ended:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="dateInvestigationEnded" name="dateInvestigationEnded"></input><br></br>
                        </div>
                    </div>

                    <div className='section-container'>
                        <div className="form-row">
                            <div className="form-col">
                                <label style={{ color: '#212529' }} for="dateCharge">Date of Charge: <PopUp content={content[8]} /></label>
                                <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="dateCharge" name="dateCharge"></input><br></br>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label style={{ color: '#212529' }} for="violations">Conduct Violations: <PopUp content={content[9]} /></label>
                                <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="violations" name="violations"></input><br></br>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label style={{ color: '#212529' }} for="sanctions">Sanctions: <PopUp content={content[10]} /></label>
                                <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="sanctions" name="sanctions"></input><br></br>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='section-container'>
                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="pendingCharges">Pending Charges: <PopUp content={content[11]} /></label>
                            <select onChange={handleInputChange} name="pendingCharges" id="pendingCharges" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="extraLinks">Extra Links: <PopUp content={content[12]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="extraLinks" name="extraLinks"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="outLetter">Outcome Letter: <PopUp content={content[13]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="outLetter" name="outLetter"></input><br></br>
                        </div>
                    </div>

                    {/* class created to adjust width of last input field to be the same as others. change class name if new input field is added */}
                    <div className="equal-width">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="news">News Coverage: <PopUp content={content[14]} /></label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="news" name="news"></input><br></br>
                        </div>
                    </div>
                </div>

                <div className='button-wrapper-submit'>
                    <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { handleInputValidation(); }}>Check Input</button>
                    {firstRender && <SubmitPopUp />}
                    {/* {<button class="btn btn-primary" onClick={e => { navToHomePage(); }}>Refresh Database</button>} */}
                    <button type="button" class="btn btn-secondary" onClick={cancelButton}>Cancel</button>
                </div>
                <div id='error'></div>
            </div>
        </div>
    )
}