import React from "react";
import "../App.css";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { HomePage } from "./HomePage";
import { AddIncidentPage } from "./AddIncidentPage";
import { ViewIncidentsPage } from "./ViewIncidentsPage";
import { ViewIncidentInfo } from "./ViewIncidentInfo";
import { EditIncidentInfo } from "./EditIncidentInfo";
import { AddSchoolProfilePage } from "./AddSchoolProfilePage";
import { EditSchoolProfile } from "./EditSchoolProfile";
import { AddFirstIncident } from "./AddFirstIncident";
import { NavBar } from "./NavBar";
import { LogIn } from "./LogIn";


function App() {
  return (
    <Auth0Provider
      domain="dev-ce6ruyjw271h4di0.us.auth0.com"
      clientId="93T2ISkE65tvvhSObeY4iuBoZYEBRK7c"
      // redirectUri="http://localhost:3000/homePage"
      redirectUri="https://hazing-info-forms.onrender.com/homePage"
      // redirectUri={window.location.origin + "/homePage"}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <NavBar />
      <Routes>
        <Route path="homePage" element={<HomePage />} />
        <Route path="addIncidentPage" element={<AddIncidentPage />} />
        <Route path="viewIncidentPage" element={<ViewIncidentsPage />} />
        <Route path="viewIncidentInfo" element={<ViewIncidentInfo />} />
        <Route path="editIncidentInfo" element={<EditIncidentInfo />} />
        <Route path="addSchoolProfilePage" element={<AddSchoolProfilePage />} />
        <Route path="editSchoolProfile" element={<EditSchoolProfile />} />
        <Route path="addFirstIncident" element={<AddFirstIncident />} />
        {/* <Route path="*" element={<LogIn />} /> */}
        <Route path="/" element={<LogIn />} />
      </Routes>
    </Auth0Provider>

  );
}
export default App;