import React, { useState } from 'react';
import "../App.css";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from 'reactjs-popup';

export function EditSchoolProfile() {

    const { isAuthenticated } = useAuth0();
    const nav = useNavigate();
    if (!isAuthenticated) {
        nav('/');
    }

    const [uniName, setUniName] = useState([]);
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    const [uniInfo, setUniInfo] = useState([]);
    // useEffect to fetch Uni Names
    React.useEffect(() => {
        fetch("/getData/uniInfo")
            .then((res) => res.json())
            .then((data) => setUniInfo(data.message));
    }, []);

    const cancelButton = () => {
        nav(-1)
    }
    const navToHomePage = async () => {
        nav('/homePage')
    }

    const navToIncidentPage = () => {
        nav('/viewIncidentPage')
    }

    const [firstRender, setFirstRender] = useState(false);
    React.useEffect(() => {
        setTimeout(() => { setFirstRender(true); }, 650);
    }, []);

    const reloadNavToHomePage = async () => {
        // nav('/homePage')
        if (!firstRender) {
            navToHomePage()
        }
    }

    // useEffect to set default values for dropdowns
    React.useEffect(() => {
        // get default values, if they exist
        const defaultPolicyGrade = uniInfo.policyGrade

        // find the necessary elements and set the default values
        if (defaultPolicyGrade != "" && defaultPolicyGrade != null) {
            document.getElementById(defaultPolicyGrade).selected = true
        }

    })

    const SubmitPopUp = () => (
        <Popup trigger={<button style={{ marginRight: '10px' }} type="button" class="btn btn-primary">Submit</button>} modal>
            <div>
                <div className='pop-up-container'>
                    <h4>School profile edited!</h4>
                    <p>You will be navigated back to Incident Page</p>
                    {/* {window.location.reload} */}
                    {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
                    <button onClick={e => { navToIncidentPage() }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
                </div>
            </div>
        </Popup>
    );

    const DeletePopUp = () => (
        <Popup trigger={<button style={{ marginRight: '10px' }} type="button" class="btn btn-secondary">Delete Profile</button>} modal>
            <div>
                <div className='pop-up-container'>
                    <h4>School Profile Successfully Deleted</h4>
                    <p>You will now be navigated back to the Home Page</p>
                    {/* {window.location.reload} */}
                    {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
                    <button onClick={e => { handleSchoolDelete(); }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
                </div>
            </div>
        </Popup>
    );

    // Note to AMAYA: When you the final inputIssueCount check for handleSave()
    // Set the if clause to setFirstRender(true) instead of handleSave
    // if (inputIssueCount == 0) {
    //     // handleSave()
    //     setFirstRender(true)
    // }
    // Let me know after you do this so I can come back and change code

    var universityName, city, state, establishedDate, schoolType, numStudents,
        schoolWebsite, greekLife, greekLifeOverview, hazingInfoLink, policyGrade

    function isValidURL(url) {
        if (url === "") {
            return true;
        }
        var pattern = new RegExp('^(https?:\/\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(url);
    }

    const handleInputValidation = async (e) => {
        var inputIssueCount = 0

        const generalValidation = /^(?=.*\S)?(?!.*[{}"']).*$/
        const emptyStringValidation = /^ +$/

        // const urlValidation = /^$|(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/

        // remove all previous error messages
        document.getElementById('error').innerHTML = ""

        {/* Validate each of the inputs*/ }
        city = document.getElementById('city')
        city.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(city.value) || emptyStringValidation.test(city.value)) {
            inputIssueCount += 1
            handleInputIssues(city)
        }

        state = document.getElementById('state')
        state.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(state.value) || emptyStringValidation.test(state.value)) {
            inputIssueCount += 1
            handleInputIssues(state)
        }

        establishedDate = document.getElementById('establishedDate')
        establishedDate.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(establishedDate.value) || emptyStringValidation.test(establishedDate.value)) {
            inputIssueCount += 1
            handleInputIssues(establishedDate)
        }

        schoolType = document.getElementById('schoolType')
        schoolType.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(schoolType.value) || emptyStringValidation.test(schoolType.value)) {
            inputIssueCount += 1
            handleInputIssues(schoolType)
        }

        numStudents = document.getElementById('numStudents')
        numStudents.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(numStudents.value) || emptyStringValidation.test(numStudents.value)) {
            inputIssueCount += 1
            handleInputIssues(numStudents)
        }

        schoolWebsite = document.getElementById('schoolWebsite')
        schoolWebsite.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(schoolWebsite.value) || !isValidURL(schoolWebsite.value) || emptyStringValidation.test(schoolWebsite.value)) {
            inputIssueCount += 1
            handleInputIssues(schoolWebsite)
        }

        greekLife = document.getElementById('greekLife')
        greekLife.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(greekLife.value) || !isValidURL(greekLife.value) || emptyStringValidation.test(greekLife.value)) {
            inputIssueCount += 1
            handleInputIssues(greekLife)
        }

        greekLifeOverview = document.getElementById('greekLifeOverview')
        greekLifeOverview.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(greekLifeOverview.value) || emptyStringValidation.test(greekLifeOverview.value)) {
            inputIssueCount += 1
            handleInputIssues(greekLifeOverview)
        }

        hazingInfoLink = document.getElementById('hazingInfoLink')
        hazingInfoLink.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(hazingInfoLink.value) || !isValidURL(hazingInfoLink.value) || emptyStringValidation.test(hazingInfoLink.value)) {
            inputIssueCount += 1
            handleInputIssues(hazingInfoLink)
        }

        policyGrade = document.getElementById('policyGrade')
        policyGrade.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(policyGrade.value) || emptyStringValidation.test(policyGrade.value)) {
            inputIssueCount += 1
            handleInputIssues(policyGrade)
        }

        {/* If all inputs are valid handleSave() but if not, then show the error message*/ }
        if (inputIssueCount == 0) {
            handleSave()
        } else {
            const errorMessage = document.createElement("p")
            errorMessage.textContent = "Please edit the inputs that are highlighted red. Check for empty spaces, curly brackets, quotation marks, and that you are including a valid URL."
            document.getElementById('error').appendChild(errorMessage)
        }
    }

    const handleInputIssues = async (element) => {
        console.log("handleInputIssues called")
        console.log("current element: " + element.id)
        element.style.backgroundColor = '#FF7276'
    }

    const handleSave = async () => {
        universityName = uniName
        city = document.getElementById('city').value
        state = document.getElementById('state').value
        establishedDate = document.getElementById('establishedDate').value
        schoolType = document.getElementById('schoolType').value
        numStudents = document.getElementById('numStudents').value
        schoolWebsite = document.getElementById('schoolWebsite').value
        greekLife = document.getElementById('greekLife').value
        greekLifeOverview = document.getElementById('greekLifeOverview').value
        hazingInfoLink = document.getElementById('hazingInfoLink').value
        policyGrade = document.getElementById('policyGrade').value

        var obj = {
            universityName: universityName,
            city: city,
            state: state,
            establishedDate: establishedDate,
            schoolType: schoolType,
            numStudents: numStudents,
            schoolWebsite: schoolWebsite,
            greekLife: greekLife,
            greekLifeOverview: greekLifeOverview,
            hazingInfoLink: hazingInfoLink,
            policyGrade: policyGrade
        };

        fetch("/postData/editSchoolProfile", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });

        // navToIncidentPage();

        window.location.reload();
        // if (firstRender) {
        //     navToHomePage();
        // }
    }
    const handleSchoolDelete = (e) => {
        universityName = uniName
        city = document.getElementById('city').value
        state = document.getElementById('state').value
        establishedDate = document.getElementById('establishedDate').value
        schoolType = document.getElementById('schoolType').value
        numStudents = document.getElementById('numStudents').value
        schoolWebsite = document.getElementById('schoolWebsite').value
        greekLife = document.getElementById('greekLife').value
        greekLifeOverview = document.getElementById('greekLifeOverview').value
        hazingInfoLink = document.getElementById('hazingInfoLink').value
        policyGrade = document.getElementById('policyGrade').value

        var obj = {
            universityName: universityName,
            city: city,
            state: state,
            establishedDate: establishedDate,
            schoolType: schoolType,
            numStudents: numStudents,
            schoolWebsite: schoolWebsite,
            greekLife: greekLife,
            greekLifeOverview: greekLifeOverview,
            hazingInfoLink: hazingInfoLink,
            policyGrade: policyGrade
        };

        fetch("/postData/deleteUni", {
            method: "DELETE",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        })

        navToHomePage();
    }

    function ProfileForm() {
        return (
            <div>
                <div id="new-school">

                    <h3>General School Information</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for="city">City:</label>
                                <input defaultValue={uniInfo.city} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="city" name="city"></input><br></br>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label for="state">State:</label>
                                <input defaultValue={uniInfo.state} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="state" name="state"></input><br></br>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label for="establishedDate">Established Date:</label>
                                <input defaultValue={uniInfo.establishedDate} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="establishedDate" name="establishedDate"></input><br></br>
                            </div>
                        </div>
                    </div>

                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for="schoolType">School Type:</label>
                                <input defaultValue={uniInfo.schoolType} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="schoolType" name="schoolType"></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for="numStudents">Number of Students:</label>
                                <input defaultValue={uniInfo.numStudents} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="number" id="numStudents" name="numStudents"></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for="schoolWebsite">School Website:</label>
                                <input defaultValue={uniInfo.schoolWebsite} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="schoolWebsite" name="schoolWebsite"></input><br></br>
                            </div>
                        </div>
                    </div>


                    <h3>Greek Life Overview</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for="greekLife">University Greek Life Website Link:</label>
                                <input defaultValue={uniInfo.greekLife} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="greekLife" name="greekLife"></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for="greekLifeOverview">Greek Life Overview:</label>
                                <input defaultValue={uniInfo.greekLifeOverview} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="greekLifeOverview" name="greekLifeOverview"></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for="hazingInfoLink">Hazing Info Link:</label>
                                <input defaultValue={uniInfo.hazingInfoLink} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="hazingInfoLink" name="hazingInfoLink"></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                {/* <label for="policyGrade">Policy Grade:</label>
                                <input defaultValue={uniInfo.policyGrade} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="policyGrade" name="policyGrade"></input><br></br> */}
                                <label style={{ color: '#212529' }} for="policyGrade">Policy Grade:</label>
                                <select name="policyGrade" id="policyGrade" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                    <option value="">Select</option>
                                    <option id="A" value="A">A</option>
                                    <option id="B" value="B">B</option>
                                    <option id="C" value="C">C</option>
                                    <option id="D" value="D">D</option>
                                    <option id="F" value="F">F</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='button-wrapper-submit'>
                        <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { { handleInputValidation() } }}>Check Input</button>
                        {/* {firstRender && <SubmitPopUp />} */}
                        <SubmitPopUp />
                        <DeletePopUp />
                        {/* <button style={{ marginRight: '10px' }} class="btn btn-primary" onClick={e => { navToHomePage(); }}>Refresh Database</button> */}
                        <button class="btn btn-secondary" onClick={e => { cancelButton() }}>Cancel</button>
                    </div>
                    <div id="error"></div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='navigation'>
                <p><Link to="/homePage" onClick={navToHomePage}>Home Page</Link>{' -> '}<Link to="/viewIncidentPage" onClick={navToIncidentPage}>Incidents Page</Link>{' -> '}Edit School Profile</p>
            </div>

            <div className='page-header'>
                <div className='header-wrapper'>
                    <h1>Edit {uniName}'s School Profile</h1>
                </div>
            </div>

            <div>
                <ProfileForm />
            </div>
        </div>
    )
}