import React, { useEffect, useState } from 'react';
import Auth0Lock from 'auth0-lock';
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';

export function NavBar() {
    // Auth0 login function
    const { loginWithRedirect } = useAuth0();
    // State for showing Auth0 lock screen
    const [showLock, setShowLock] = useState(false);
    // Auth0 authentication and user data
    const { logout, isAuthenticated, user } = useAuth0();
    // State for storing email
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            // Set the email when user is authenticated
            setEmail(user.email);
        }
    }, [isAuthenticated, user]);

    const lock = new Auth0Lock(
        '93T2ISkE65tvvhSObeY4iuBoZYEBRK7c', // Auth0 client ID
        'dev-ce6ruyjw271h4di0.us.auth0.com', // Auth0 domain
        {
            theme: {
                logo: './imgs/logo.png', // Custom logo image for the lock screen
                primaryColor: '#24305E' // Custom primary color for the lock screen
            },
            languageDictionary: {
                title: 'Welcome!', // Title displayed on the lock screen
                loginSubmitLabel: 'Log In', // Label for the login button on the lock screen
            },
            allowedConnections: ['hazingDB'], // Allowed connection(s) for authentication
            rememberLastLogin: true, // Remember the last login for the user
            initialScreen: 'login', // Initial screen to be shown in the lock screen
            closable: true, // Display a close button on the lock screen
            autofocus: true, // Automatically focus on the lock screen
            allowSignUp: false, // Disable sign up option on the lock screen
            auth: {
                // URL to redirect after successful authentication
                // DO NOT CHANGE (in local branch refers to localhost)
                // Will refer to 'deployed-website/homePage' in main branch
                redirectUrl: "https://hazing-info-forms.onrender.com/homePage",
                // redirectUrl: 'http://localhost:3000/homePage',
                responseType: 'token id_token', // Response type for authentication
                audience: 'https://dev-ce6ruyjw271h4di0.us.auth0.com/api/v2/', // API audience for authentication
                params: {
                    scope: 'openid profile email', // Requested scope for authentication
                },
            },
        }
    );

    const handleLogoutClick = () => {
        // Perform logout and return to the current origin
        logout({ returnTo: 'https://hazing-info-forms.onrender.com/' })
        // logout({ returnTo: window.location.origin });
    };

    const handleLoginClick = async () => {
        setShowLock(true); // Show the lock screen for login
        loginWithRedirect();
    };

    lock.on('authenticated', async (authResult) => {
        setShowLock(false); // Hide the lock screen
        if (authResult && authResult.mode === 'resetPassword') {
            lock.hide(); // Hide the lock screen if it was used for resetting password
        } else {
            loginWithRedirect(); // Perform login with redirect
        }
    });

    const handleChangePasswordClick = async () => {
        lock.show({
            mode: 'resetPassword', // Set the mode to reset password
            allowForgotPassword: true, // Allow the user to enter their email to receive password reset instructions
            allowAutocomplete: true, // Allow autocomplete for the email field
            callbackURL: "https://hazing-info-forms.onrender.com/",
            // callbackURL: 'http://localhost:3000/', // URL to return to after password reset
            rememberLastLogin: true, // Remember the last login for the user
            initialScreen: 'forgotPassword', // Initial screen to be shown in the lock screen for password reset
            flashMessage: {
                type: 'success', // Flash message type (success, error, warning, info)
                text: 'Please reset your password to continue.', // Text for the flash message
            },
        });
    };

    const dropdownOptions = [
        { label: 'Logout', value: 'logout' }, // Option for logging out
        { label: 'Change Password', value: 'changePassword' }, // Option for changing password
    ];

    const handleDropdownChange = (selectedOption) => {
        if (selectedOption.value === 'logout') {
            handleLogoutClick(); // Logout if the logout option is selected
        } else if (selectedOption.value === 'changePassword') {
            handleChangePasswordClick(); // Change password if the change password option is selected
        }
    };

    return (
        <nav>
            <ul style={{ backgroundColor: '#24305E', marginBottom: '0rem', paddingLeft: '1rem' }}>
                {isAuthenticated ? (
                    <>
                        <div className='nav-container'>
                            <div className='nav-dropdown-container'>
                                <Select
                                    options={dropdownOptions}
                                    onChange={handleDropdownChange}
                                    placeholder={email}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <button className="nav-button" onClick={handleLoginClick}>Login</button>
                        </div>
                    </>
                )}
            </ul>
            {showLock && lock.show()} {/* Show the lock screen if `showLock` state is `true` */}
        </nav>
    );
}
