import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from 'reactjs-popup';

export function AddSchoolProfilePage() {

    const { isAuthenticated } = useAuth0();
    const nav = useNavigate();
    if (!isAuthenticated) {
        nav('/');
    }
    const cancelButton = () => {
        nav(-1)
    }
    const submitButton = () => {
        nav('./')
    }

    const navToHomePage = async () => {
        nav('/homePage')
    }


    const [firstRender, setFirstRender] = useState(false);
    // React.useEffect(() => {
    //     setTimeout(() => { setFirstRender(false); }, 650);
    // }, []);

    var universityName, city, state, establishedDate, schoolType, numStudents,
        schoolWebsite, greekLife, greekLifeOverview, hazingInfoLink, policyGrade

        function isValidURL(url) {
            if(url === "") {
                return true;
            }
            var pattern = new RegExp('^(https?:\/\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return pattern.test(url);
        }

        const handleInputValidation = async (e) => {
            var inputIssueCount = 0
    
            const generalValidation = /^(?=.*\S)?(?!.*[{}"']).*$/
            const emptyStringValidation = /^ +$/
            
            // const urlValidation = /^$|(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/
    
            // remove all previous error messages
            document.getElementById('error').innerHTML = ""

            {/* Validate each of the inputs*/ }
            universityName = document.getElementById('universityName')
            universityName.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(universityName.value) || emptyStringValidation.test(universityName.value)) {
                inputIssueCount += 1
                handleInputIssues(universityName)
            }

            city = document.getElementById('city')
            city.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(city.value) || emptyStringValidation.test(city.value)) {
                inputIssueCount += 1
                handleInputIssues(city)
            }

            state = document.getElementById('state')
            state.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(state.value) || emptyStringValidation.test(state.value)) {
                inputIssueCount += 1
                handleInputIssues(state)
            }

            establishedDate = document.getElementById('establishedDate')
            establishedDate.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(establishedDate.value) || emptyStringValidation.test(establishedDate.value)) {
                inputIssueCount += 1
                handleInputIssues(establishedDate)
            }

            schoolType = document.getElementById('schoolType')
            schoolType.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(schoolType.value) || emptyStringValidation.test(schoolType.value)) {
                inputIssueCount += 1
                handleInputIssues(schoolType)
            }

            numStudents = document.getElementById('numStudents')
            numStudents.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(numStudents.value) || emptyStringValidation.test(numStudents.value)) {
                inputIssueCount += 1
                handleInputIssues(numStudents)
            }

            schoolWebsite = document.getElementById('schoolWebsite')
            schoolWebsite.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(schoolWebsite.value) || !isValidURL(schoolWebsite.value) || emptyStringValidation.test(schoolWebsite.value)) {
                inputIssueCount += 1
                handleInputIssues(schoolWebsite)
            }

            greekLife = document.getElementById('greekLife')
            greekLife.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(greekLife.value) || !isValidURL(greekLife.value) || emptyStringValidation.test(greekLife.value)) {
                inputIssueCount += 1
                handleInputIssues(greekLife)
            }

            greekLifeOverview = document.getElementById('greekLifeOverview')
            greekLifeOverview.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(greekLifeOverview.value) || emptyStringValidation.test(greekLifeOverview.value)) {
                inputIssueCount += 1
                handleInputIssues(greekLifeOverview)
            }

            hazingInfoLink = document.getElementById('hazingInfoLink')
            hazingInfoLink.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(hazingInfoLink.value) || !isValidURL(hazingInfoLink.value) || emptyStringValidation.test(hazingInfoLink.value)) {
                inputIssueCount += 1
                handleInputIssues(hazingInfoLink)
            }

            policyGrade = document.getElementById('policyGrade')
            policyGrade.style.backgroundColor = '#EFEFEF'
            if (!generalValidation.test(policyGrade.value) || emptyStringValidation.test(policyGrade.value)) {
                inputIssueCount += 1
                handleInputIssues(policyGrade)
            }

             {/* If all inputs are valid handleSave() but if not, then show the error message*/ }
            if (inputIssueCount == 0) {
                // handleSave()
                setFirstRender(true)
            } else {
                const errorMessage = document.createElement("p")
                errorMessage.textContent = "Please edit the inputs that are highlighted red. Check for empty spaces, curly brackets, quotation marks, and that you are including a valid URL."
                document.getElementById('error').appendChild(errorMessage)
            }
        }

        const handleInputIssues = async (element) => {
            console.log("handleInputIssues called")
            console.log("current element: " + element.id)
            element.style.backgroundColor = '#FF7276'
        }

    const handleSave = async (e) => {
        universityName = document.getElementById('universityName').value
        city = document.getElementById('city').value
        state = document.getElementById('state').value
        establishedDate = document.getElementById('establishedDate').value
        schoolType = document.getElementById('schoolType').value
        numStudents = document.getElementById('numStudents').value
        schoolWebsite = document.getElementById('schoolWebsite').value
        greekLife = document.getElementById('greekLife').value
        greekLifeOverview = document.getElementById('greekLifeOverview').value
        hazingInfoLink = document.getElementById('hazingInfoLink').value
        policyGrade = document.getElementById('policyGrade').value

        var obj = {
            universityName: universityName,
            city: city,
            state: state,
            establishedDate: establishedDate,
            schoolType: schoolType,
            numStudents: numStudents,
            schoolWebsite: schoolWebsite,
            greekLife: greekLife,
            greekLifeOverview: greekLifeOverview,
            hazingInfoLink: hazingInfoLink,
            policyGrade: policyGrade
        };

        fetch("/postData/addSchoolProfile", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });

        window.location.reload()
    }

    const SubmitPopUp = () => (
        <Popup trigger={<button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { }}>Submit</button>} modal>
            <div>
                <div className='pop-up-container'>
                    <h4>Successfully added to DB</h4>
                    <p>You may add another school profile</p>
                    {/* {window.location.reload} */}
                    {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
                    <button onClick={e => { handleSave(); }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
                </div>
            </div>
        </Popup>
    );

    const handleInputChange = (event) => {
        const newVal = event.target
        // Reset the firstRender state when changes are made to trigger revalidation
        setFirstRender(false);
    };

    return (
        <div>
            <div className='navigation'>
                <p><Link to="/homePage" onClick={navToHomePage}>Home Page</Link>{' -> '}Add a New School Profile Page</p>
            </div>


            <div className='page-header'>
                <div className='header-wrapper'>
                    <h1>Add A New School Profile</h1>
                </div>
            </div>

            <div id="new-school">

                <h3>General School Information</h3>
                <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                    <div className="form-row">
                        <div className="form-col">
                            <label for="universityName">University Name:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="universityName" name="universityName"></input><br></br>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label for="city">City:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="city" name="city"></input><br></br>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label for="state">State:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="state" name="state"></input><br></br>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label for="establishedDate">Established Date:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="establishedDate" name="establishedDate"></input><br></br>
                        </div>
                    </div>
                </div>

                <div className='section-container' style={{ paddingBottom: '1.5rem' }}>

                    <div className="form-row">
                        <div className="form-col">
                            <label for="schoolType">School Type:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="schoolType" name="schoolType"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label for="numStudents">Number of Students:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="number" id="numStudents" name="numStudents"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label for="schoolWebsite">School Website:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="schoolWebsite" name="schoolWebsite"></input><br></br>
                        </div>
                    </div>
                </div>

                <h3>Greek Life Overview</h3>
                <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                    <div className="form-row">
                        <div className="form-col">
                            <label for="greekLife">University Greek Life Website Link:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="greekLife" name="greekLife"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label for="greekLifeOverview">Greek Life Overview:</label>
                            <textarea onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="greekLifeOverview" name="greekLifeOverview"></textarea><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label for="hazingInfoLink">Hazing Info Link:</label>
                            <input onChange={handleInputChange} class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type="text" id="hazingInfoLink" name="hazingInfoLink"></input><br></br>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <label style={{ color: '#212529' }} for="policyGrade">Policy Grade:</label>
                            <select onChange={handleInputChange} name="policyGrade" id="policyGrade" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                <option value="">Select</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="F">F</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='button-wrapper-submit'>
                    <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { { handleInputValidation() } }}>Check Input</button>
                    {firstRender && <SubmitPopUp />}
                    <button class="btn btn-secondary" onClick={cancelButton}>Back to Home Page</button>
                </div>
                <div id="error"></div>
            </div>
        </div>
    )
}