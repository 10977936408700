import React, { useState } from 'react';
import "../App.css";
import { IncidentTable } from './IncidentTable';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from 'reactjs-popup';

export function EditIncidentInfo() {

    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    if (!isAuthenticated) {
        navigate('/');
    }

    const [uniName, setUniName] = useState('');
    const [reportData, setReportData] = useState([]);

    // useEffect to fetch Uni Name
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    // useEffect to fetch Report Data
    React.useEffect(() => {
        fetch("/getData/selectedReportData")
            .then((res) => res.json())
            .then((data) => setReportData(data.message));
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navToIncidentInfo = () => {
        navigate('/viewIncidentInfo')
    }

    const navToIncidents = () => {
        navigate('/viewIncidentPage')
    }

    const [firstRender, setFirstRender] = useState(false);

    const SaveEditPopUp = () => (
        <Popup trigger={<button style={{ marginRight: '10px' }} class="btn btn-primary">Submit</button>} modal>
            <div>
                <div className='pop-up-container'>
                    <h4>Successfully saved to DB</h4>
                    <p>
                        You will be navigated to Home Page to rebuild endpoints
                        and reflect new edits
                    </p>
                    {/* {window.location.reload} */}
                    {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
                    {/* <button onClick={e => { handleInputValidation(); navToIncidents(); }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button> */}
                    <button onClick={e => { navToHomePage() }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
                </div>
            </div>
        </Popup>
    );

    // useEffect to set default values for dropdowns
    React.useEffect(() => {
        // get default values, if they exist
        const defaultGreekType = reportData.greekType
        const defaultIncidentSeverity = reportData.incidentSeverity
        const defaultPendingCharges = reportData.pendingCharges

        // find the necessary elements and set the default values
        if (defaultGreekType != "" && defaultGreekType != null) {
            document.getElementById(defaultGreekType).selected = true
        }

        if (defaultIncidentSeverity != "" && defaultIncidentSeverity != null) {
            document.getElementById(defaultIncidentSeverity).selected = true
        }

        if (defaultPendingCharges != "" && defaultPendingCharges != null) {
            document.getElementById(defaultPendingCharges).selected = true
        }

    })

    const navToHomePage = async () => {
        navigate('/homePage')
    }
    const cancelButton = () => {
        navigate(-1)
    }


    var chapterName, chapterSymbol, type, severity, description, entity, dateInvestigation,
        dateInvestigationEnded, dateCharge, violations, sanctions, outLetter, news, extraLink, greekType,
        incidentDate, pendingCharge, incidentYear

    function isValidURL(url) {
        if(url === "") {
            return true;
        }
        var pattern = new RegExp('^(https?:\/\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(url);
    }

    const handleInputValidation = async (e) => {
        var inputIssueCount = 0

        const generalValidation = /^(?=.*\S)?(?!.*[{}"']).*$/
        const emptyStringValidation = /^ +$/
        // const urlValidation = /^$|(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/

        // remove all previous error messages
        document.getElementById('error').innerHTML = ""

        {/* Validate each of the inputs*/ }
        chapterName = document.getElementById('chapterName')
        chapterName.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(chapterName.value) || emptyStringValidation.test(chapterName.value)) {
            inputIssueCount += 1
            handleInputIssues(chapterName)
        }

        chapterSymbol = document.getElementById('chapterSymbol')
        chapterSymbol.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(chapterSymbol.value) || emptyStringValidation.test(chapterSymbol.value)) {
            inputIssueCount += 1
            handleInputIssues(chapterSymbol)
        }

        type = document.getElementById('type')
        type.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(type.value) || emptyStringValidation.test(type.value)) {
            inputIssueCount += 1
            handleInputIssues(type)
        }

        incidentDate = document.getElementById('incidentDate')
        incidentDate.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(incidentDate.value) || emptyStringValidation.test(incidentDate.value)) {
            inputIssueCount += 1
            handleInputIssues(incidentDate)
        }

        greekType = document.getElementById('greekType')
        greekType.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(greekType.value) || emptyStringValidation.test(greekType.value)) {
            inputIssueCount += 1
            handleInputIssues(greekType)
        }

        severity = document.getElementById('severity')
        severity.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(severity.value) || emptyStringValidation.test(severity.value)) {
            inputIssueCount += 1
            handleInputIssues(severity)
        }

        description = document.getElementById('description')
        description.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(description.value) || emptyStringValidation.test(description.value)) {
            inputIssueCount += 1
            handleInputIssues(description)
        }

        entity = document.getElementById('entity')
        entity.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(entity.value) || emptyStringValidation.test(entity.value)) {
            inputIssueCount += 1
            handleInputIssues(entity)
        }

        dateInvestigation = document.getElementById('dateInvestigation')
        dateInvestigation.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateInvestigation.value) || emptyStringValidation.test(dateInvestigation.value)) {
            inputIssueCount += 1
            handleInputIssues(dateInvestigation)
        }

        dateInvestigationEnded = document.getElementById('dateInvestigationEnded')
        dateInvestigationEnded.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateInvestigationEnded.value) || emptyStringValidation.test(dateInvestigationEnded.value)) {
            inputIssueCount += 1
            handleInputIssues(dateInvestigationEnded)
        }

        dateCharge = document.getElementById('dateCharge')
        dateCharge.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(dateCharge.value) || emptyStringValidation.test(dateCharge.value)) {
            inputIssueCount += 1
            handleInputIssues(dateCharge)
        }

        incidentYear = document.getElementById('incidentYear')
        incidentYear.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(incidentYear.value) || emptyStringValidation.test(incidentYear.value)) {
            inputIssueCount += 1
            handleInputIssues(incidentYear)
        }

        violations = document.getElementById('violations')
        violations.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(violations.value) || emptyStringValidation.test(violations.value)) {
            inputIssueCount += 1
            handleInputIssues(violations)
        }

        sanctions = document.getElementById('sanctions')
        sanctions.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(sanctions.value) || emptyStringValidation.test(sanctions.value)) {
            inputIssueCount += 1
            handleInputIssues(sanctions)
        }

        outLetter = document.getElementById('outLetter')
        outLetter.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(outLetter.value) || !isValidURL(outLetter.value) || emptyStringValidation.test(outLetter.value)) {
            inputIssueCount += 1
            handleInputIssues(outLetter)
        }

        news = document.getElementById('news')
        news.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(news.value) || !isValidURL(news.value) || emptyStringValidation.test(news.value)) {
            inputIssueCount += 1
            handleInputIssues(news)
        }

        extraLink = document.getElementById('extraLink')
        extraLink.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(extraLink.value) || !isValidURL(extraLink.value) || emptyStringValidation.test(extraLink.value)) {
            inputIssueCount += 1
            handleInputIssues(extraLink)
        }

        pendingCharge = document.getElementById('pendingCharges')
        pendingCharge.style.backgroundColor = '#EFEFEF'
        if (!generalValidation.test(pendingCharge.value) || emptyStringValidation.test(pendingCharge.value)) {
            inputIssueCount += 1
            handleInputIssues(pendingCharge)
        }

        {/* If all inputs are valid handleSave() but if not, then show the error message*/ }
        if (inputIssueCount == 0) {
            handleSave()
        } else {
            const errorMessage = document.createElement("p")
            errorMessage.textContent = "Please edit the inputs that are highlighted red. Check for empty spaces, curly brackets, quotation marks, and that you are including a valid URL."
            document.getElementById('error').appendChild(errorMessage)
        }
    }

    const handleInputIssues = async (element) => {
        console.log("handleInputIssues called")
        console.log("current element: " + element.id)
        element.style.backgroundColor = '#FF7276'
    }

    const handleSave = async (e) => {
        chapterName = document.getElementById('chapterName').value
        chapterSymbol = document.getElementById('chapterSymbol').value
        type = document.getElementById('type').value
        incidentDate = document.getElementById('incidentDate').value
        greekType = document.getElementById('greekType').value
        severity = document.getElementById('severity').value
        description = document.getElementById('description').value
        entity = document.getElementById('entity').value
        dateInvestigation = document.getElementById('dateInvestigation').value
        dateInvestigationEnded = document.getElementById('dateInvestigationEnded').value
        dateCharge = document.getElementById('dateCharge').value
        violations = document.getElementById('violations').value
        sanctions = document.getElementById('sanctions').value
        outLetter = document.getElementById('outLetter').value
        news = document.getElementById('news').value
        extraLink = document.getElementById('extraLink').value
        pendingCharge = document.getElementById('pendingCharges').value
        incidentYear = document.getElementById('incidentYear').value

        var obj = {
            chapterName: chapterName,
            incidentDate: incidentDate,
            chapterSymbol: chapterSymbol,
            greekType: greekType,
            type: type,
            severity: severity,
            description: description,
            entity: entity,
            dateInvestigation: dateInvestigation,
            dateInvestigationEnded: dateInvestigationEnded,
            dateCharge: dateCharge,
            violations: violations,
            sanctions, sanctions,
            outLetter, outLetter,
            news: news,
            extraLink: extraLink,
            pendingCharge: pendingCharge,
            incidentYear: incidentYear
        };

        fetch("/postData/updateIncidentInfo", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });

        // window.location.reload();
    }

    return (
        <div>
            <div className='navigation'>
                <p><Link to="/homePage" onClick={navToHomePage}>Home Page</Link>{' -> '}<Link to="/viewIncidentPage" onClick={navToIncidents}>Incidents Page</Link>{' -> '}<Link to="/viewIncidentPage" onClick={cancelButton}>Incident Info Page</Link>{' -> '}Edit Info Page</p>
            </div>
            {/*INCIDENT PAGE START: move this and link to incidents table */}
            <div className='incident-report body'>
                <div className='page-header'>
                    <div className='header-wrapper'>
                        <h1 className='incident-title'>Edit Incident Info</h1>
                    </div>
                </div>
                <div className='edit-incident-container'>
                    <h3>General Info</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for='chapterName'>Chapter Name:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='chapterName' name='chapterName' defaultValue={reportData.greekOrganizationName}></input><br></br>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='chapterSymbol'>Greek Chapter Symbol:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='chapterSymbol' name='chapterSymbol' defaultValue={reportData.greekSymbol} size="5"></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                {/* <label for='greekType'>Greek Type:</label> */}
                                {/* <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='greekType' name='greekType' defaultValue={reportData.greekType}></input> */}
                                <label style={{ color: '#212529' }} for="greekType">Greek Chapter Type:</label>
                                <select name="greekType" id="greekType" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                    <option value="">Select</option>
                                    <option id="Fraternity" value="Fraternity">Fraternity</option>
                                    <option id="Sorority" value="Sorority">Sorority</option>
                                    <option id="Other" value="Other">Other</option>
                                </select>
                            </div>

                        </div>
                        <div className='form-row'>
                            <div className="form-col">
                                <label for='type'>Incident Type:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='type' name='type' defaultValue={reportData.incidentType} ></input>
                            </div>
                        </div>

                    </div> {/* section container closing div */}
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                {/* <label for='severity'>Incident Severity:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='severity' name='severity' defaultValue={reportData.incidentSeverity}></input> */}
                                <label style={{ color: '#212529' }} for="severity">Incident Severity: </label> {/* Indimidation, Harassment, Violence */}
                                <select name="severity" id="severity" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                    <option value="">Select</option>
                                    <option id="Intimidation" value="Intimidation">Intimidation</option>
                                    <option id="Harassment" value="Harassment">Harassment</option>
                                    <option id="Violence" value="Violence">Violence</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='description'>Incident Description:</label>
                                <textarea class='form-control' style={{ width: '500px', paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='description' name='description' defaultValue={reportData.incidentDescription}></textarea>
                            </div>
                        </div>
                    </div>

                    <h3>Formal Investigation</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for='entity'>Investigation Entity:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='entity' name='entity' defaultValue={reportData.investEntity}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='incidentDate'>Incident Date:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='incidentDate' name='incidentDate' defaultValue={reportData.incidentDate}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='dateInvestigation'>Investigation Date Initiated:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='dateInvestigation' name='dateInvestigation' defaultValue={reportData.dateInvestigation}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='dateInvestigationEnded'>Investigation Date Ended:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='dateInvestigationEnded' name='dateInvestigationEnded' defaultValue={reportData.dateInvestigationEnded}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='dateCharge'>Charge Date:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='dateCharge' name='dateCharge' defaultValue={reportData.dateCharge}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='incidentYear'>Incident Year:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='number' id='incidentYear' name='incidentYear' defaultValue={reportData.incidentYear}></input>
                            </div>
                        </div>
                    </div> {/* section container closing div */}

                    <h3>Investigation Results</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for='violations'>Guilty of the Following Conduct Violations:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='violations' name='violations' defaultValue={reportData.violations}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                {/* <label for='pendingCharge'>Pending Charges:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='pendingCharge' name='pendingCharge' defaultValue={reportData.pendingCharges}></input> */}
                                <label style={{ color: '#212529' }} for="pendingCharges">Pending Charges:</label>
                                <select name="pendingCharges" id="pendingCharges" class='form-control' style={{ backgroundColor: '#EFEFEF' }}>
                                    <option value="">Select</option>
                                    <option id="Yes" value="Yes">Yes</option>
                                    <option id="No" value="No">No</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='sanctions'>Sanctions:</label>
                                <textarea class='form-control' style={{ width: '500px', paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='sanctions' name='sanctions' defaultValue={reportData.sanctions}></textarea>
                            </div>
                        </div>
                    </div> {/* section container closing div */}
                    <h3>Other Resources</h3>
                    <div className='section-container' style={{ paddingBottom: '1.5rem' }}>
                        <div className="form-row">
                            <div className="form-col">
                                <label for='outLetter'>Outcome Letter:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='outLetter' name='outLetter' defaultValue={reportData.outcomeLetter}></input>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <label for='news'>News Coverage:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='news' name='news' defaultValue={reportData.newsCoverage}></input>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label for='extraLink'>Extra Link:</label>
                                <input class='form-control' style={{ paddingRight: '10px', backgroundColor: '#EFEFEF' }} type='text' id='extraLink' name='extraLink' defaultValue={reportData.extraLinks}></input>
                            </div>
                        </div>
                    </div> {/* section container closing div */}
                    <div>
                        <br></br>
                        <button style={{ marginRight: '10px' }} class="btn btn-primary" onClick={e => { handleInputValidation(); }}>Check Input</button>
                        <SaveEditPopUp />
                        {/* <button style={{ marginRight: '10px' }} class="btn btn-primary" onClick={e => { navToHomePage(); }}>Refresh Database</button> */}
                        <button style={{ marginRight: '10px' }} class="btn btn-secondary" onClick={cancelButton}>Cancel</button>
                    </div>
                    <div id='error'></div>
                    
                </div> {/*edit-incident-body closing div */}
            </div>
            {/*INCIDENT PAGE END: move this and link to incidents table */}
        </div>
    )
}