import React, { useState } from 'react';
import "../App.css";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { SchoolDropDown } from './SchoolDropDown';
import { IncidentTable } from './IncidentTable';
import { useAuth0 } from '@auth0/auth0-react';

// Remember to add props when handeling search

function AddIncidentButton(props) {

    const nav = useNavigate();

    const navToHomePage = async () => {
        nav('/homePage')
    }

    const navToAddIncident = () => {
        nav('/addIncidentPage')
    }

    const handleReportClickTwo = (e) => {
        var obj = {
            reportViewData: props.resources
        };

        fetch("/postData/reportViewData", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }
    return (
        <div>
            <button type="button" class="btn btn-primary" onClick={e => { handleReportClickTwo(); navToAddIncident(); }}>Add A New Incident</button>
        </div>
    )
}

export function ViewIncidentsPage() {

    const [uniName, setUniName] = useState('');

    const nav = useNavigate();
    const navToHomePage = () => {
        nav('/homePage')
    }
    const { isAuthenticated } = useAuth0();
    if (!isAuthenticated) {
        nav('/');
    }

    const [schoolsInIncidents, setSchoolsInIncidents] = useState([]);

    // useEffect to fetch Uni Name
    React.useEffect(() => {
        fetch("/getData/schoolsInIncidents")
            .then((res) => res.json())
            .then((data) => setSchoolsInIncidents(data.message));
    }, []);


    // useEffect to fetch Uni Name
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    const [incidentInfo, setIncidentInfo] = useState([]);
    // useEffect to fetch Uni Incidents
    React.useEffect(() => {
        fetch("/getData/incidents")
            .then((res) => res.json())
            .then((data) => setIncidentInfo(data.message));
    }, []);

    const [firstRender, setFirstRender] = useState(true);
    React.useEffect(() => {
        setTimeout(() => { setFirstRender(false); }, 450);
    }, []);

    const [uniInfo, setUniInfo] = useState([]);
    // useEffect to fetch Uni Names
    React.useEffect(() => {
        fetch("/getData/uniInfo")
            .then((res) => res.json())
            .then((data) => setUniInfo(data.message));
    }, []);

    function PageContent() {
        const navToAddFirst = () => {
            nav('/addFirstIncident')
        }

        if (schoolsInIncidents.includes(uniName)) {
            return (
                <div>
                    <div>
                        <div className='incident-paragraph'>
                            <p>The table below displays incidents affilated with the school selected on the home page. <br></br>
                                To add a new incident, click the button below and follow the instructions.<br></br>
                                The new incident should appear below in our existing list of incidents. </p>
                            {/* <button type="button" class="btn btn-primary" onClick={navToAddIncident}>Add A New Incident</button> */}
                            <AddIncidentButton resources={incidentInfo} />
                        </div>
                    </div>

                    <div className='table-wrapper'>
                        <IncidentTable />
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div>
                        <div className='incident-paragraph'>
                            <p>No incident data exists for {uniName}. You may add the first incident
                                using the button below:</p>

                            <button type="button" onClick={navToAddFirst} class="btn btn-primary">Add First Incident</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function SchoolProfile(props) {

        const navToEditProfile = () => {
            nav('/editSchoolProfile')
        }

        const sendUniInfo = (e) => {
            var obj = {
                uniInfo: props.resources
            };

            fetch("/postData/currentUniInfo", {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(obj)
            });
        }

        return (
            <div className='body-UniPage'>
                <div className='profile-container'> {/* container for entire general profile info and school name */}
                    <div className='university-name'>
                        <h2>{uniName}</h2>
                    </div>

                    <div class='flex-container-profile'>
                        <div className='school-profile'>
                            <h3>School Profile</h3>
                            <p><b>Type: </b>{uniInfo.schoolType}</p>
                            <p><b>Location: </b>{uniInfo.city}, {uniInfo.state}</p>
                            <p><b>Established: </b>{uniInfo.establishedDate}</p>
                            <p><b>Total Undergrate Students: </b>{uniInfo.numStudents}</p>
                            <p><b>Website: </b> <a class='blue-link' href={uniInfo.schoolWebsite}>{uniName}</a> </p>
                        </div>

                        <div className='greek-overview'>
                            <h3>Greek Life Overview</h3>
                            <p>{uniInfo.greekLifeOverview}</p>
                            <p>Learn more about {uniName} Greek Life <a class='blue-link' href={uniInfo.greekLife}>here</a>!</p>
                        </div>
                    </div>
                </div>
                <div className='button-wrapper' style={{ paddingLeft: '4rem' }}>
                    <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { navToEditProfile(); sendUniInfo(); }}>Edit School Profile</button>
                </div>
            </div>
        )
    }

    return (
        <div>

            {/* {reloadOnce} */}

            <div className='navigation'>
                <p><Link to="/homePage" onClick={navToHomePage}>Home Page</Link>{' -> '}Incidents Page</p>
            </div>

            <div className='page-header'>
                <div className='header-wrapper'>
                    <h1>View Incident Page</h1>
                </div>
            </div>

            {firstRender && <div><h2>Loading School Profile...</h2></div>}
            {!firstRender && <SchoolProfile resources={uniInfo} />}

            {firstRender && <div><h2>Loading Incident List...</h2></div>}
            {!firstRender && <PageContent />}
        </div>
    )
}
