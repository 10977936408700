import React, { useState } from 'react';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export function LogIn() {

    const { isAuthenticated } = useAuth0(); // Auth0 authentication status
    const nav = useNavigate(); // React Router navigate function
    // const navToHome = () => {
    //     nav('/homePage'); // Function to navigate to the home page
    // }

    // if (isAuthenticated) {
    //     navToHome(); // If authenticated, navigate to the home page
    // }

    React.useEffect(() => {
        console.log("useEffect in LogIn component executed. isAuthenticated:", isAuthenticated);
        if (isAuthenticated) {
            console.log("Navigating");
            nav('/homePage');
        }
    }, [isAuthenticated]);

    return (
        // Do NOT put content outside this div tag
        <div className='home-body'>
            <div className='page-header'>
                <h1>Welcome to HazingInfo Forms</h1>
            </div>
            <div className='button-wrapper'>
                Authenticate using the Login button found in the navigation
                bar on top.
            </div>
        </div>
    )
}
