import React, { useState } from 'react';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'

// Remember to add props when handeling search
export function SchoolDropDown(props) {

    const navigate = useNavigate();
    const [selectSchool, setSelectSchool] = useState('');

    const navToViewIncident = () => {
        if (selectSchool == '') {
            navigate('/homePage');
        } else {
            navigate('/viewIncidentPage');
        }
    };

    const handleInputChange = (input) => {
        setSelectSchool(input.value)
    }

    const handleSearch = (e) => {
        var obj = {
            uniName: selectSchool
        };

        fetch("/getData/uniName", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }

    const handleSearchTwo = (e) => {
        var obj = {
            uniName: selectSchool
        };

        fetch("/postData/uniName", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }

    const schoolNamesArray = (props.schoolNames.map(opt => ({ label: opt, value: opt })))

    const customStyles = {
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            color: state.isSelected ? "#000" : "#000",
            backgroundColor: state.isSelected ? "#a0a0a0" : "#fff",
        }),

        control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: "#EFEFEF",
            padding: "1px",
            border: "none",
            boxShadow: "none",
        }),
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
    };

    // const defaultID = () => {
    //     searchVal = '';
    // }

    // const RenderButton = () => {
    //     if (searchVal == '') {
    //         return (
    //             <div>
    //                 <button type="button" class="btn btn-primary">View/Edit Incidents</button>
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <div>
    //                 <button type="button" class="btn btn-primary" onClick={e => { handleSearch(); handleSearchTwo(); navToViewIncident(); }}>View/Edit Incidents</button>
    //             </div>
    //         )
    //     }
    // }

    const RenderButton = () => {
        return (
            <div>
                <button type="button" class="btn btn-primary" onClick={e => { handleSearch(); handleSearchTwo(); navToViewIncident(); }}>View and Edit Incidents</button>
            </div>
        )
    }

    return (
        <div style={{ width: '500px' }}>
            <div style={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}>
                <Select
                    options={schoolNamesArray}
                    id='school-name'
                    defaultValue={"Select a University"}
                    placeholder="Select a University"
                    styles={customStyles}
                    onChange={handleInputChange}>
                    {/* <option value='Select a University'> -- Select a University -- </option>
                {props.schoolNames.map((name) => <option value={name}>{name}</option>)} */}
                </Select>
            </div>
            <div>
                <RenderButton />
            </div>
            {/* <button type="button" class="btn btn-primary" onClick={e => { handleSearch(); handleSearchTwo(); navToViewIncident(); }}>View/Edit Incidents</button> */}
        </div>
    );
}