import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SchoolDropDown } from './SchoolDropDown';
import { useAuth0 } from '@auth0/auth0-react';

export function HomePage() {
    // Auth0 authentication status
    const { isAuthenticated } = useAuth0();
    // React Router navigate function
    const nav = useNavigate();

    // Function to navigate to the add incident page
    const navToAddIncident = () => {
        nav('/addIncidentPage');
    }

    // If not authenticated, navigate back to the login page
    if (!isAuthenticated) {
        nav('/');
    }

    // Function to navigate to the add school profile page
    const navToAddSchoolProfile = () => {
        nav('/addSchoolProfilePage');
    }

    // State for storing school names
    const [schoolNames, setSchoolNames] = useState([]);

    // useEffect to fetch School Names
    React.useEffect(() => {
        fetch("/getData/schoolNames") // Fetch school names from the server
            .then((res) => res.json())
            .then((data) => setSchoolNames(data.message)); // Set the school names in state
    }, []);

    return (
        // Do NOT put content outside this div tag
        <div className='home-body'>
            <div className='page-header'>
                <div className='header-wrapper'>
                    <h1>Home Page</h1>
                </div>
            </div>
            <div className='button-wrapper'>
                Select a school to view its incident data!
                <SchoolDropDown schoolNames={schoolNames} /> {/* Render the SchoolDropDown component */}
            </div>

            <div className='button-wrapper'>
                Can't find the school you are looking for? Add a New School Profile:
                <br></br>
                <div style={{ paddingTop: '0.5rem' }}>
                    <button type="button" className="btn btn-primary" onClick={navToAddSchoolProfile}>Add A New School Profile</button>
                </div>
            </div>
        </div>
    )
}
