import React, { useState } from 'react';
import "../App.css";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from 'reactjs-popup';

export function ViewIncidentInfo() {

  const nav = useNavigate();
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    nav('/');
  }

  const [uniName, setUniName] = useState('');
  const [reportData, setReportData] = useState([]);

  // useEffect to fetch Uni Name
  React.useEffect(() => {
    fetch("/getData/selectedUniName")
      .then((res) => res.json())
      .then((data) => setUniName(data.message));
  }, []);

  // useEffect to fetch Report Data
  React.useEffect(() => {
    fetch("/getData/selectedReportData")
      .then((res) => res.json())
      .then((data) => setReportData(data.message));
  }, []);
  // console.log(reportData)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();

  const navToEditIncident = () => {
    navigate('/editIncidentInfo')
  }

  const navToHomePage = async () => {
    nav(-1)
  }
  const navHome = async () => {
    nav('/homePage')
  }

  const navToIncidentPage = () => {
    nav('/viewIncidentPage')
  }

  const handleIncidentDelete = (e) => {
    var obj = {
      reportViewData: reportData
    };
    fetch("/postData/deleteIncident", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    // window.location.reload()
    navHome();
  }

  const checkGreekSymbol = () => {
    if (reportData.greekSymbol == '') {
      return (
        <span>
          <p>{reportData.greekOrganizationName} at the {uniName}</p>
        </span>
      )
    } else {
      return (
        <span>
          <p>{reportData.greekOrganizationName} ({reportData.greekSymbol}) at the {uniName}</p>
        </span>
      )
    }
  }

  const DeletePopUp = () => (
    <Popup trigger={<button type="button" class="btn btn-secondary">Delete Incident</button>} modal>
      <div>
        <div className='pop-up-container'>
          <h4>Incident Successfully Deleted</h4>
          <p>You will now be navigated back to the Home Page</p>
          {/* {window.location.reload} */}
          {/* <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" >OK</button> */}
          <button onClick={e => { handleIncidentDelete(); }} style={{ marginRight: '10px' }} type="button" class="btn btn-primary">OK</button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div>
      <div className='navigation'>
        <p><Link to="/homePage" onClick={navHome}>Home Page</Link>{' -> '}<Link to="/viewIncidentPage" onClick={navToHomePage}>Incidents Page</Link>{' -> '}Incident Info Page</p>
      </div>
      <div className='page-header'>
        <h2 className='incident-title'>Hazing Incident Info</h2>
      </div>
      {/*INCIDENT PAGE START: move this and link to incidents table */}
      <div className='incident-report-body'>


        <h4>Chapter Name</h4>
        {checkGreekSymbol()}
        <h4>Incident Type</h4>
        <p>{reportData.incidentType}</p>
        <h4>Incident Severity</h4>
        <p>{reportData.incidentSeverity}</p>
        <h4>Incident Description</h4>
        <p>{reportData.incidentDescription}</p>
        <h4>Formal Investigation</h4>
        <p>
          <b>Investigating Entity:</b> {reportData.investEntity}<br />
          <b>Investigating Date Initiated:</b> {reportData.dateInvestigation}<br />
          <b>Investigating Date Concluded:</b> {reportData.dateInvestigationEnded}<br />
          <b>Charge Date:</b> {reportData.dateCharge}<br />
          <b>Incident Year:</b> {reportData.incidentYear}
        </p>
        <h4>Investigation Results</h4>
        <p><b>Guilty of the Following Conduct Violations:</b></p>
        <p>{reportData.violations}</p>
        <p><b>Sanctions:</b></p>
        <p>{reportData.sanctions}</p>
        <h4>Other Resources</h4>
        <p><b>Outcome Letter:</b></p>
        <p>{reportData.outcomeLetter}</p>
        <p><b>News Coverage:</b></p>
        <p>{reportData.newsCoverage}</p>
        <p><b>Extra Link: </b><a href={reportData.extraLinks}>View Report</a></p>
      </div>

      {/*INCIDENT PAGE END: move this and link to incidents table */}
      <div className='button-wrapper' style={{ paddingLeft: '4rem' }}>
        <button style={{ marginRight: '10px' }} type="button" class="btn btn-primary" onClick={e => { navToEditIncident(); }}>Edit Incident Details</button>
        {/* <button type="button" class="btn btn-secondary" onClick={e => { handleIncidentDelete(); }}>Delete Incident</button> */}
        <DeletePopUp />
        {/* <button class="btn btn-primary" onClick={e => { navToHomePage(); }}>Refresh Database</button> */}
      </div>
    </div>
  )
}